import {useEffect, useRef, useState} from 'react';
import googleMapsLoader from '@mgp-fe/shared/utils/google-maps-loader.ts';
import {transformAddressComponentsToAddress} from '@mgp-fe/shared/utils/transform-address-components.ts';
import {AddressModel} from '@mgp-fe/shared/core-api/domain/address.ts';
import {HydraModel} from '@mgp-fe/shared/core-api/domain/base.ts';
import {useFormContext} from 'react-hook-form';

interface UseGoogleAddressAutocompleteProps {
	addressField: string;
}

export const useGoogleAddressAutocomplete = (
	{
		addressField,
	}: UseGoogleAddressAutocompleteProps) => {

	const form = useFormContext();
	const [googleAddress, setGoogleAddress] = useState<Omit<AddressModel, keyof HydraModel> | null>(null);
	const lineOneRef = useRef<HTMLInputElement>();
	const gmAutocompleteRef = useRef<google.maps.places.Autocomplete>();
	const [googleAddressAutocompleteInitialized, setGoogleAddressAutocompleteInitialized] = useState(false);
	const countryCode = form.watch(`${addressField}.countryCode`);
	useEffect(() => {
		(async () => {
			if (lineOneRef.current && !googleAddressAutocompleteInitialized) {
				const gm = await googleMapsLoader.importLibrary('places');
				gmAutocompleteRef.current = new gm.Autocomplete(lineOneRef.current, {
					componentRestrictions: {country: ['us', 'ca', 'au']},
					fields: ['address_components', 'geometry', 'icon', 'name'],
					strictBounds: false,
					types: ['address'],
				});
				setGoogleAddressAutocompleteInitialized(true);
			}
		})();
	}, [lineOneRef.current]);

	useEffect(() => {
		if (!gmAutocompleteRef.current) return;
		google.maps.event.addListener(gmAutocompleteRef.current, 'place_changed', () => {
			const place = gmAutocompleteRef.current?.getPlace();
			const autocompletedAddress = transformAddressComponentsToAddress(
				place?.address_components ?? [],
				place?.geometry?.location?.toJSON(),
			);
			const currentAddress = form.getValues(addressField) || {};
			const updatedAddress = {
				...currentAddress,
				...autocompletedAddress,
			};

			form.setValue(addressField, updatedAddress);
			form.setValue(`${addressField}.lineOne`, autocompletedAddress.lineOne);
			setGoogleAddress(updatedAddress);
		});
	}, [gmAutocompleteRef.current]);

	useEffect(() => {
		if (countryCode && googleAddress) {
			const currentAddress = form.getValues(addressField) || {};
			const updatedAddress = {
				...currentAddress,
				...googleAddress,
			};
			form.setValue(addressField, updatedAddress);
		}
	}, [countryCode, googleAddress]);

	return {
		googleAddress,
		lineOneRef,
		countryCode,
	};
};
